function displayLocationCircle(mapId) {
    const mapElement = document.getElementById(mapId);
    const lat = mapElement.dataset.lat;
    const lon = mapElement.dataset.lon;

    const mapOptions = {
        streetViewControl: false,
        mapTypeControl: false,
        zoomControlOptions: {
            position: google.maps.ControlPosition.LEFT_BOTTOM,
        },
        center: {
            lat: Number(lat),
            lng: Number(lon),
        },
        zoom: 11,
        mapId: '94ec441e8da9b5f2',
    };

    const map = new google.maps.Map(mapElement, mapOptions);
    const target = mapOptions.center;
    const circleOnMap = new google.maps.Circle({
        center: target,
        radius: 750,
        strokeColor: '#0000FF',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#0000FF',
        fillOpacity: 0.4,
    });


    circleOnMap.setMap(map);
}

export { displayLocationCircle };


