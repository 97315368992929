function smoothScroll(pageNavigationId, pageNavItemClass) {
    let pageNavigationHeight = document.getElementById(pageNavigationId).offsetHeight;
    let pageNavItems = document.getElementsByClassName(pageNavItemClass);
    let itemsHref = [];
    let navSections = [];

    //Check if element is in the viewport
    function isAnyPartOfElementInViewport(el) {
        const rect = el.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        return rect.top <= windowHeight && rect.top + rect.height >= 0;
    }

    //Deselect all page navigation items
    function deselectAllHrefs(itemsHref) {
        if (itemsHref) {
            for (let i = 0; i < itemsHref.length; i++) {
                let activeElement = document.querySelectorAll('a[href="' + '#' + itemsHref[i] + '"]')[0];
                activeElement.classList.remove('active');
            }
        }
    }

    Array.from(pageNavItems).forEach(function (i) {
        //Extract href values from pageNavItems and push them to itemsHref
        let hrefValue = i.href.substr(i.href.indexOf('#') + 1);
        itemsHref.push(hrefValue);
        //Get elements that have href values set as id and push them to navSections
        navSections.push(document.getElementById(hrefValue));
    });

    Array.from(pageNavItems).forEach((item, index) => {
        item.addEventListener('click', function (event) {
            event.preventDefault();
            let currentY = window.pageYOffset;
            let sectionPosition = navSections[index].getBoundingClientRect().top;
            let offsetPosition = sectionPosition + currentY - pageNavigationHeight;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });
        });
    });

    window.addEventListener('scroll', function () {
        let isFirstElementSelected = false;

        itemsHref.forEach((value, index) => {
            let element = document.getElementById(value);

            if (element) {
                if (isAnyPartOfElementInViewport(element)) {
                    if (!isFirstElementSelected) {
                        deselectAllHrefs(itemsHref);

                        let activeElement = document.querySelectorAll('a[href="' + '#' + value + '"]')[0];

                        if (activeElement) activeElement.classList.add('active');

                        isFirstElementSelected = true;
                    }
                } else {
                    let activeElement = document.querySelectorAll('a[href="' + '#' + value + '"]')[0];
                    if (activeElement) activeElement.classList.remove('active');
                }
            }
        });
    });
}

export default smoothScroll;
