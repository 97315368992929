import './bootstrap';
// import '../css/app.css';
// import ('./gallery');
import smoothScroll from './smoothScroll';
import { displayLocationCircle } from './googleMaps';
import filtersLayout from '@js/vue/logic/filtersLayout';

/* detect IE and display message */
var isIE = /MSIE|Trident/.test(window.navigator.userAgent);
if (isIE) {
    document.getElementById('msie').style.display = 'block';
}

window.dataLayer = window.dataLayer || [];

// hamburger & sidebar
(function () {
    // sidebar height calculation
    window.addEventListener('load', () => {
        const setSidebarHeight = () => {
            const navbar = document.getElementById('navbar');
            const style = `calc(100vh - ${navbar.clientHeight}px)`;
            const sidebarContainer = document.querySelector('.sidebar-container');
            if (sidebarContainer) {
                sidebarContainer.style.height = style;
                sidebarContainer.style.top = navbar.clientHeight + 'px';
            }
        };

        const mediaQuery = window.matchMedia('(max-width: 1024px)');

        if (mediaQuery.matches) {
            setSidebarHeight();
        }

        mediaQuery.onchange = (e) => {
            if (e.matches) {
                setSidebarHeight();
            }
        };
    });
    const hamburger = document.querySelector('.hamburger-menu');
    if (hamburger) {
        hamburger.addEventListener('click', function () {
            if (hamburger.classList.contains('open')) {
                hamburger.classList.remove('open');
                document.body.classList.remove('prevent-scroll');
            } else {
                hamburger.classList.add('open');
                document.body.classList.add('prevent-scroll');
            }
        });
    }
})();
// logged in user dropdown on desktop
(function () {
    const userDetails = document.querySelector('.dropdown > .user-details');
    if (userDetails) {
        const navbarDropdown = document.querySelector('.navbar-dropdown');
        userDetails.addEventListener('click', function (event) {
            event.preventDefault();
            if (userDetails.classList.contains('open')) {
                userDetails.classList.remove('open');
                navbarDropdown.style.display = 'none';
            } else {
                userDetails.classList.add('open');
                navbarDropdown.style.display = 'block';
            }
        });
    }
})();

// service page navigation - smooth scroll
if (document.getElementById('page-navigation')) {
    smoothScroll('page-navigation', 'service-menu-link');
}

const map = document.getElementById('myMap');
if (map) {
    window.initMap = function () {
        displayLocationCircle('myMap');
    };
    // check if the map is visible to user, if it is load Google maps - lazy loading
    document.addEventListener('scroll', () => {
        if (isElementInViewport(map) && !map.dataset.loaded) {
            const script = document.createElement('script');
            script.src =
                'https://maps.googleapis.com/maps/api/js?key=AIzaSyDe7e4GGv5quU23qqr-RgOOQWNedfrPHfE&libraries=places&callback=initMap';
            document.head.append(script);
            map.dataset.loaded = true;
        }
    });
}

// home page - categories & testimonials
const customCarousels = document.getElementsByName('custom-carousel');
if (customCarousels) {
    customCarousels.forEach((carousel) => {
        document.addEventListener('DOMContentLoaded', function () {
            import('@splidejs/splide').then((Splide) => {
                new Splide.default(carousel, {
                    type: 'loop',
                    pagination: false,
                    perPage: 4,
                    perMove: 1,
                    autoplay: 'play',
                    arrowPath:
                        'M30.1708 18.6167C30.1795 18.6248 30.1881 18.633 30.1967 18.6411C31.2678 19.6677 31.2678 21.3323 30.1967 22.3589L14.6824 37.23C13.6114 38.2567 11.8748 38.2567 10.8038 37.23C9.73277 36.2034 9.73277 34.5389 10.8038 33.5123L24.3785 20.5003L10.8033 7.48767C9.73224 6.4611 9.73224 4.79676 10.8033 3.76993C11.8743 2.74336 13.6108 2.74336 14.6819 3.76993L30.1708 18.6167Z',
                    classes: {
                        arrows: 'splide-custom-arrow',
                    },
                    breakpoints: {
                        1024: {
                            perPage: 2,
                        },
                        640: {
                            perPage: 1,
                        },
                    },
                }).mount();
            });
        });
    });
}

if (document.getElementById('testimonials-carousel')) {
    document.addEventListener('DOMContentLoaded', function () {
        import('@splidejs/splide').then((Splide) => {
            new Splide.default('#testimonials-carousel', {
                type: 'loop',
                autoplay: true,
            }).mount();
        });
    });
}

if (document.getElementById('connect-testimonials-carousel')) {
    document.addEventListener('DOMContentLoaded', function () {
        import('@splidejs/splide').then((Splide) => {
            new Splide.default('#connect-testimonials-carousel', {
                type: 'fade',
                autoplay: true,
                pagination: false,
            }).mount();
        });
    });
}

// notification
(function () {
    const notification = document.querySelector('.notification');
    if (notification) {
        // get rid of notification after 10 seconds
        setTimeout(() => {
            notification.remove();
        }, 10000);
    }
})();

// newsletter form
(function () {
    const newsletterForm = document.getElementById('newsletterForm');
    if (newsletterForm) {
        newsletterForm.addEventListener('submit', function (e) {
            e.preventDefault();
            const email = this.elements['email'].value;
            const recaptcha = this.elements['recaptcha'].value;
            const newsletterMessage = document.querySelector('.newsletter-message');
            newsletterMessage.style.display = 'none';
            newsletterMessage.classList.remove('error');
            axios
                .post('/api/subscriptions/general', {
                    email,
                    recaptcha,
                })
                .then((response) => {
                    dataLayer.push({
                        event: 'newsletterConfirmed',
                    });
                    newsletterMessage.innerText = newsletterMessage.dataset.success;
                })
                .catch((err) => {
                    const { data } = err.response;
                    newsletterMessage.innerText = data?.message ? data.message : newsletterMessage.dataset.error;
                    newsletterMessage.classList.add('error');
                })
                .finally(() => (newsletterMessage.style.display = 'block'));
        });
    }
})();

// contact sales form
(function () {
    const contactSalesForm = document.getElementById('contactSalesForm');
    if (contactSalesForm) {
        contactSalesForm.addEventListener('submit', function (e) {
            e.preventDefault();
            const name = this.elements['name'].value;
            const phone = this.elements['phone'].value;
            const email = this.elements['email'].value;
            const recaptcha = this.elements['recaptcha'].value;
            const formMessage = document.querySelector('.form-message');
            formMessage.style.display = 'none';
            formMessage.classList.remove('error');
            axios
                .post('/api/authland-connect', {
                    name,
                    phone,
                    email,
                    recaptcha,
                })
                .then((response) => {
                    // TODO Add a GA event
                    formMessage.innerText = formMessage.dataset.success;
                })
                .catch((err) => {
                    const { data } = err.response;
                    formMessage.innerText = data?.message ? data.message : formMessage.dataset.error;
                    formMessage.classList.add('error');
                })
                .finally(() => (formMessage.style.display = 'block'));
        });
    }
})();

/*Authland Connect Fade In Start*/
function handleIntersection(entries, observer) {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.classList.add('visible');
            observer.unobserve(entry.target);
        }
    });
}

const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
};

const intersectionObserver = new IntersectionObserver(handleIntersection, observerOptions);
const elements = document.querySelectorAll('.animate-on-scroll');

elements.forEach((el) => {
    intersectionObserver.observe(el);
});
/*Authland Connect Fade In End*/

// modal open/close
const modal = document.querySelector('.modal');
if (modal) {
    const openModalButtons = document.querySelectorAll('.open-modal');
    const body = document.getElementsByTagName('body')[0];

    openModalButtons.forEach((openBtn) => {
        openBtn.addEventListener('click', function (e) {
            e.preventDefault();

            if (openBtn.dataset.title) {
                dataLayer.push({
                    event: 'bookingStart',
                    experience: openBtn.dataset.title,
                    location: openBtn.dataset.location,
                    experienceType: openBtn.dataset.type,
                });
            }

            if (modal.classList.contains('is-active')) {
                modal.classList.remove('is-active');
                body.classList.remove('prevent-scroll');
                document.dispatchEvent(new Event('modal:closed'));
            } else {
                modal.classList.add('is-active');
                body.classList.add('prevent-scroll');
                document.dispatchEvent(new Event('modal:opened'));
            }
        });
    });
    const closeButtons = modal.querySelectorAll('.close');
    closeButtons.forEach((closeBtn) => {
        closeBtn.addEventListener('click', function (e) {
            e.preventDefault();
            modal.classList.remove('is-active');
            body.classList.remove('prevent-scroll');
            document.dispatchEvent(new Event('modal:closed'));
        });
    });
}

// open modal after one second if the URL contains "modal" parameter
if (window.location.search.includes('modal')) {
    // wait Vue to generate component
    setTimeout(function () {
        modal.classList.add('is-active');
        document.body.classList.add('prevent-scroll');
        document.dispatchEvent(new Event('modal:opened'));
    }, 1000);
}

(function () {
    // Accordions - open/close
    const allAccordions = document.querySelectorAll('.accordion');
    if (allAccordions) {
        allAccordions.forEach((oneAccordion) => {
            const theAccordionHeader = oneAccordion.querySelector('button.accordion-header');
            theAccordionHeader.addEventListener('click', function () {
                const isExpanded = this.getAttribute('aria-expanded');
                const accordionBody = oneAccordion.querySelector('.accordion-body');

                if (isExpanded === 'true') {
                    accordionBody.style.display = 'none';
                    this.setAttribute('aria-expanded', false);
                } else {
                    accordionBody.style.display = 'block';
                    this.setAttribute('aria-expanded', true);
                }
            });
        });
    }
})();

// trigger filter on home page
(function () {
    const heroForm = document.getElementById('hero-form');
    if (heroForm) {
        const destinationSelect = document.getElementById('destination');
        const categorySelect = document.getElementById('category');

        const allCategories = Array.from(categorySelect.options).map((option) => {
            return {
                id: option.dataset.id,
                slug: option.value,
                name: option.text,
            };
        });

        destinationSelect.addEventListener('change', (ev) => {
            const destinationId = destinationSelect.options[ev.target.selectedIndex].dataset.id;

            if (destinationId) {
                axios.get(`/api/destinations/${destinationId}/categories`).then((result) => {
                    // clear all options except the first one (empty/placeholder option)
                    categorySelect.options.length = 1;
                    result.data.forEach((category) => {
                        categorySelect.options.add(createOption(category));
                    });
                });
                return;
            }
            // otherwise, clear all inside select than add all categories
            categorySelect.options.length = 0;
            allCategories.forEach((category) => categorySelect.options.add(createOption(category)));
        });

        heroForm.addEventListener('submit', function (event) {
            event.preventDefault();
            const selectedDestination = document.getElementById('destination').value;
            const selectedCategory = document.getElementById('category').value;

            dataLayer.push({
                event: 'searchBarSubmit',
                location: selectedDestination,
                experienceType: selectedCategory ? selectedCategory : 'not set',
            });

            const path = window.lang + '/' + selectedDestination + (selectedCategory ? '/' + selectedCategory : '');

            window.location.pathname = path;
        });
    }

    function createOption(category) {
        const option = document.createElement('option');
        option.value = category.slug;
        option.dataset.id = category.id;
        option.text = category.name.trim();
        return option;
    }
})();

// Services page -  display arrows & enable scroll; display list and map view; sort by price
(function () {
    const sliderMenuArrows = document.querySelectorAll('.slider-menu-arrow');
    if (sliderMenuArrows.length) {
        const layout = filtersLayout();
        layout.initializeLayout();
        sliderMenuArrows.forEach((sliderArrow) => {
            sliderArrow.addEventListener('click', function (event) {
                event.preventDefault();
                const arrowDirection = sliderArrow.getAttribute('id');
                layout.horizontalScroll(arrowDirection);
            });
        });
    }
})();

(function () {
    const sliderMenu = document.getElementById('sliderMenu');
    if (sliderMenu) {
        const leftArrow = document.getElementById('left');
        const rightArrow = document.getElementById('right');
        const scrollRight = sliderMenu.scrollWidth - sliderMenu.scrollLeft - sliderMenu.offsetWidth;
        let scrolling = false;

        window.addEventListener('load', function () {
            if (sliderMenu.scrollLeft > 0) leftArrow.classList.add('show');
            if (scrollRight > 0) rightArrow.classList.add('show');
        });

        sliderMenu.addEventListener('scroll', function () {
            scrolling = true;
        });

        setInterval(() => {
            if (scrolling) {
                scrolling = false;
                const updatedScrollRight = sliderMenu.scrollWidth - sliderMenu.scrollLeft - sliderMenu.offsetWidth;
                sliderMenu.scrollLeft > 0 ? leftArrow.classList.add('show') : leftArrow.classList.remove('show');
                updatedScrollRight > 0 ? rightArrow.classList.add('show') : rightArrow.classList.remove('show');
            }
        }, 300);
    }
})();

(function () {
    const displayListView = document.getElementById('display-list-view');
    if (displayListView) {
        const layout = filtersLayout();
        layout.initializeLayout();
        displayListView.addEventListener('click', function (event) {
            event.preventDefault();
            layout.displayListView();
        });
    }
})();

(function () {
    const displayMapView = document.getElementById('display-map-view');
    if (displayMapView) {
        const layout = filtersLayout();
        layout.initializeLayout();
        displayMapView.addEventListener('click', function (event) {
            event.preventDefault();
            layout.displayMapView();
        });
    }
})();

(function () {
    const displaySortTrigger = document.getElementById('display-sort');
    if (displaySortTrigger) {
        const displaySortMob = document.getElementById('sort-by-price-mob');
        displaySortTrigger.addEventListener('click', function (event) {
            event.preventDefault();
            if (displaySortMob.style.display === 'none') displaySortMob.style.display = 'flex';
            else displaySortMob.style.display = 'none';
        });
    }
})();

(function () {
    const sortSelect = document.getElementsByName('sort-by-price-value');
    if (sortSelect) {
        sortSelect.forEach((oneSortSelect) => {
            oneSortSelect.addEventListener('change', () => {
                dataLayer.push({
                    event: 'sortingApplied',
                    sortingType: oneSortSelect.value,
                });
                window.test.applyServiceFilters(false, oneSortSelect.value, true);
            });
        });
    }
})();

(function () {
    const resetSort = document.getElementsByName('reset-sort');
    if (resetSort) {
        resetSort.forEach((btnReset) => {
            btnReset.addEventListener('click', () => {
                window.test.applyServiceFilters(false, null, true);
                const resetSortFilter = document.getElementsByName('sort-by-price-value');
                resetSortFilter.forEach((oneOptionSelect) => {
                    oneOptionSelect.selectedIndex = 0;
                });
            });
        });
    }
})();

// Service display page - send dataLayer when offer card is expanded
(function () {
    const offerCards = document.getElementsByName('offer-card');
    if (offerCards.length) {
        const serviceTitle = document.getElementsByClassName('service-title')[0].innerHTML;

        offerCards.forEach((card) => {
            card.addEventListener('click', () => {
                if (card.getAttribute('aria-expanded') === 'true') {
                    dataLayer.push({ event: 'offersExpand', experience: serviceTitle });
                }
            });
        });
    }
})();

const forms = document.getElementsByTagName('form');
Array.from(forms).forEach((form) => {
    // form doesn't have recaptcha
    if (!form.dataset.recaptcha) return;
    // recaptcha is already loaded
    let recaptchaIsLoaded = false;
    Array.from(form.elements).forEach((element) => {
        element.addEventListener('input', () => {
            if (!recaptchaIsLoaded) {
                document.querySelectorAll('script[data-recaptcha]').forEach((script) => {
                    script.type = 'text/javascript';
                    document.head.append(script);
                    recaptchaIsLoaded = true;
                });
            }
        });
    });
});

// helpers
function isElementInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

//Hero Slider
(function () {
    document.addEventListener('DOMContentLoaded', function () {
        const images = document.querySelectorAll('.bg-image');
        let currentImageIndex = getRandomIndex(images.length);

        function showImage(index) {
            images.forEach((image, i) => {
                if (i === index) {
                    image.classList.add('active');
                } else {
                    image.classList.remove('active');
                }
            });
        }

        function nextImage() {
            currentImageIndex = (currentImageIndex + 1) % images.length;
            showImage(currentImageIndex);
        }

        const interval = 6000;
        setInterval(nextImage, interval);

        function getRandomIndex(max) {
            return Math.floor(Math.random() * max);
        }

        showImage(currentImageIndex);
    });
})();

(function () {
    document.querySelectorAll('a[href^="#newsletter"]').forEach((anchor) => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();

            const targetId = this.getAttribute('href').substring(1);
            const targetElement = document.getElementById(targetId);

            if (targetElement) {
                const navbarElement = document.getElementById('navbar');

                if (navbarElement) {
                    // Get the computed height of the navbar
                    const navbarHeight = navbarElement.offsetHeight;
                    // Calculate the offset from the top of the page to the target element
                    const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY;
                    window.scrollTo({
                        top: targetPosition - navbarHeight,
                        behavior: 'smooth',
                    });
                }
            }
        });
    });
})();
